.karriereContainer{
    
    padding-top: 180px;
    padding-bottom: 120px;
    background-image: url(./map.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f8f8f8;
    background-position: center;


    h1{
        font-size: 70px;
        line-height: 90px;
        text-align: center;
        padding: 0px;
        margin: 0px;
        
        @media (max-width: 800px){
            font-size: 34px;
            line-height: 38px;
        }
    }

    
    p{
        color: #2e3747;
        text-align: center;
        padding: 0px;
        margin: 0px;
        padding-top: 20px;
        padding-bottom: 50px;
        font-size: 18px;
    }
}