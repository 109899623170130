footer{

    background-color: #000;
    padding: 35px 0px;

    .logo-footer{
        width: 250px;
    }

    @media (max-width: 600px){
        .logo-footer-wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    ul {

        margin: 0px;
        padding: 0px;

        li a{
            display: block;
            color: #f5f5f5;
            margin-bottom: 10px;
            text-decoration: none;
            font-size: 16px;
            transition: all .3s ease;
            cursor: pointer;
        }

        li p{
            display: block;
            margin: 0px;
            color: #f5f5f5;
            margin-bottom: 10px;
            text-decoration: none;
            font-size: 16px;
            transition: all .3s ease;
        }

        h4{
            margin-top: 0;
            color: #fff;
            font-size: 16px;
            padding-left: 0;
            font-weight: 600;
            line-height: 24px;
            text-transform: uppercase;
        }

        color: white;
        text-decoration: none;
        list-style: none;
    }

    .social-wrapper{
        display: flex;
        align-items: center;
        justify-content: end;
        height: 100%;
        width: 100%;
        
        a{
            color: white;
            padding-left: 20px;
        }

        @media (max-width: 600px){
            justify-content: center;
        }
    }

    .footer-copyright{
        display: flex;
        margin: 24px 0 0;
        padding-top: 24px;
        color: #fff;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #4b5567;
        background: #000;

        a{
            text-decoration: none;
            color: white;
        }
        
        p{
            margin: 0px;
        }
        @media (max-width: 600px){
            flex-direction: column;
            p {
                text-align: center;
            }
        }

        
    }

    
    
}