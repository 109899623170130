.buchungContainer{
    padding-top: 200px;
    padding-bottom: 120px;

    .mobiledatepicker-wrapper{
        padding-top: 16.5px;
        padding-bottom: 13px;
        padding-left: 5px;
        background-color: #fafafa;
    }

    .mobiledatepicker-label{
        color: rgba(0, 0, 0, 0.6);
        left: 15px;
        top: -8px;
        font-size: 12px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }

    .inputitem-label{
        display: none;
    }

    .inputitem-section{
        padding-left: 0px;
    }

    .inputitem-section svg{
        display: none;
    }

    .ga-autocomplete{
        padding-left: 0px;
        padding-top: 10px;
        padding-bottom: 8px;
    }

    .ga-autocomplete .css-1fdsijx-ValueContainer{
        padding-left: 4px;
    }

    .rabatt-success{
        margin-bottom: 0px;
        color: green;
    }

    .rabatt-error{
        margin-bottom: 0px;
        color: red;
    }

    .buchung-success{
        color: green;
    }

    .buchung-error{
        color: red;
    }

    .klassen-inhalte-titel{
        margin: 0px;
        padding-bottom: 10px;
    }

    .paymentitem{
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: row;
        width: 100%;

        @media (max-width: 500px){
            flex-direction: column;
            justify-content: start;
            align-items: start;
        }
    }

    .payment-inner{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;


        img{
            width: 100px;
        }
    }

    .klassen-inhalte{
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        padding-bottom: 10px;    
    
        p{
            margin: 0px;
        }
        svg{
            padding-bottom: 3px;
            padding-right: 10px;
        }
    }

}