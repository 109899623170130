.datenschutzContainer{
    padding-top: 140px;
    padding-bottom: 120px;

    h2{
        margin-bottom: 0px;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    h3{
        margin-bottom: 0px;
    }

    p{
        margin: 0px;
    }

    .space-bottom{
        padding-bottom: 20px;
    }

    .nomargin{
        margin: 0px;
    }

    @media (max-width: 1200px){
        padding-top: 120px;
        padding-bottom: 100px;
    }

}