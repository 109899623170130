.banner{

    position: relative;
    min-height: 100vh;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;

    &:before, &:after{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        content: "";
        position: absolute;
    }
    &:before{
        //background: rgba(0, 0, 0, 0.5);

    }

    

    .video{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        -o-object-fit: cover;
        object-fit: cover;
        -moz-appearance: none;
        appearance: none;
        z-index: 0;
        -webkit-appearance: none;
    }

    .banner-inner{
        position: relative;
        z-index: 3;
        padding-top: 180px;
        padding-left: 24px;
        padding-right: 24px;

    }

    @media only screen and (max-width: 600px) {
        .banner-inner {
            padding-top: 120px;
            padding-left: 0px;
            padding-right: 0px;
        }
    }

}
