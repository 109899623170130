.kontaktContainer{
    
    padding-top: 180px;
    padding-bottom: 120px;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #fff;

    h1{
        font-size: 70px;
        line-height: 90px;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    h2{
        font-size: 24px;
        width: 100%;
        margin: 0px;
        padding-bottom: 20px;
        
    }

    .textWrapper{
        width: 100%;
        padding-bottom: 30px;
    }

    p{
        color: #2e3747;
        width: 100%;
        text-align: left;
        padding: 0px;
        margin: 0px;
    }

    .space-bottom-opening{
        padding-bottom: 20px;
    }

    .opening-table{
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 300px;
    }

    @media (max-width: 900px){
        .bewerbung-form-wrapper{
            padding-bottom: 50px;
        }
    }

    .kontakt-success{
        padding-top: 10px;
        color: green;
    }

    .kontakt-error{
        padding-top: 10px;
        color: red;
    }
}