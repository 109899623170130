.homeContent{
    padding: 80px 0;

    .homeContent-card{
        h2{
            color: #000;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px;
            margin-top: 0;
            margin-bottom: 8px;
        }
        p{
            color: #2e4048;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 28px;
        }
    }

    .homeContent-img{
        img{
            width: 100%;
            border-radius: 24px;
        }
    }

    .homeContent-items{
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: start;
        padding-bottom: 0px;
        

        svg{
            width: 20px;
            height: 20px;
            padding-top: 3px;
        }

        p{
            line-height: 28px;
            margin: 0px;
            padding-top: 1px;
            padding-left: 10px;
            font-size: 18px;
            color: black;

            b{
                font-weight: 500;
            }
        }
    }

    @media only screen and (max-width: 768px){
        padding: 40px 0;
    }

}