.leistungenContainer{
    padding-top: 200px;
    padding-bottom: 120px;

    

    h3{
        font-family: "Mukta", sans-serif;
        line-height: 30px;
        font-size: 20px;
        font-weight: 400;
        margin: 0px;
    }

    .leistungenContent-card{
        h2{
            color: #000;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px;
            margin-top: 0;
            margin-bottom: 8px;
            padding-bottom: 20px;
        }
        p{
            color: #2e4048;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 28px;
        }
    }

    .leistungenContent-items{
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        padding-bottom: 10px;

        p{
            line-height: 24px;
            margin: 0px;
            padding-top: 1px;
            padding-left: 10px;
            font-size: 20px;
        }
    }

    .leistungenContent-card2{
        p{
            color: #2e4048;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 28px;
        }
    }

    .leistungenContent-img{
        img{
            width: 100%;
            border-radius: 24px;
        }
    }

    .leistungen-items-titel{
        font-size: 20px;
        font-weight: 500;
        margin: 0px;
    }

    @media only screen and (max-width: 768px){
        padding-top: 180px;
    }

}