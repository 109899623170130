.flotte-home{
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
  padding: 80px 0 80px;
  background-color: #f5f5f5;
  display: block;

  @media only screen and (max-width: 768px){
    padding: 40px 0;
  }
  

  h3{
    font-size: 22px;
    font-weight: 400;
    margin: 0px;
  }

  .flotte-beschreibung{
    h2{
      color: #000;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px;
      margin-top: 0;
      margin-bottom: 8px;
      text-align: center;
    }
    p{
      font-weight: 300;
      color: #4b5566;
      line-height: 28px;
      font-size: 18px;
      text-align: center;
      margin: 0px;
      padding-bottom: 40px;
    }
  }

  .flotteitem-icon-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    padding-left: 15px;

    p{
      margin: 0px;
      color: #4c4c4c;
    }
 
  }
    
  .swiper-wrapper{
    padding-bottom: 50px;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active{
    background: #000;
  }

  .flotte-item{
    width: 100%;
  }

  .swiper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 12px 24px 0 rgba(0,0,0,.1);
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: grab;
    border-radius: 20px;
  }

  .swiper-slide img:active {
	cursor: grabbing;
  }
  
}
