.bewerbungContainer{
    
    padding-top: 80px;
    padding-bottom: 120px;
    background-repeat: no-repeat;
    background-size: contain;

    .bewerbung-form-wrapper{
        width: 100%;
        padding-top: 40px;
    }

    .karriere-success{
        padding-top: 20px;
        color: green;
    }

    .karriere-error{
        padding-top: 20px;
        color: red;
    }

    h1{
        width: 100%;
        font-size: 30px;
        line-height: 40px;
        text-align: left;
        padding: 0px;
        padding-top: 30px;
        margin: 0px;

        @media (max-width: 800px){
            padding-top: 50px;
            font-size: 34px;
            line-height: 38px;
        }
    }

    b{
        width: 100%;
        padding: 0px;
        margin: 0px;
        text-align: left;
        padding-top: 30px;
    }

    ul{
        width: 100%;
        margin: 0px;
        text-align: left;
        color: #2e3747;
        box-sizing: border-box;
    }

    i{
        color: #2e3747;
        width: 100%;
        padding: 0px;
        margin: 0px;
        text-align: left;
        padding-top: 30px;
    }

    .lebenslaufdesc{
        color: black;
        padding-bottom: 5px;
    }

    p{
        width: 100%;
        color: #2e3747;
        text-align: left;
        padding: 0px;
        margin: 0px;
    }

    .zurueck{

        display: flex;
        justify-content: start;
        align-items: center;

        a{
            color: #1e2731;
            display: flex;
            align-items: center;
            padding-top: 25px;
            cursor: pointer;
            text-decoration: none;
            transition: all .3s ease;

            h4{
                padding: 0px;
                margin: 0px;
                padding-top: 2px;
                padding-left: 5px;
            }
        }
    }
}