.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #555;
    position: relative;

    #languagepicker a{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    nav{
      align-items: center;
    }

    .MuiToolbar-regular{
      max-width: 1600px;
      width: 1600px;
      justify-content: space-between;
      
      @media (max-width: 1500px){
        width: 100%;
      }
      @media (max-width: 600px){
        max-width: none;
        flex-direction: row-reverse;
      }
    }

    .navbar-logo-wrapper{
      display: flex;
      align-items: center;

      @media (max-width: 1700px){
        padding-left: 20px;
      }
    }
    
    .logo{
      max-width: 200px;
      padding-top: 20px;
      padding-bottom: 20px;
      
      @media (max-width: 1600px){
        max-width: 175px;
      }

    }

    .wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1500px;
  
      .search {
        display: flex;
        align-items: center;
        border: 0.5px solid lightgray;
        padding: 3px;
  
        input {
          border: none;
          outline: none;
          background: transparent;
  
          &::placeholder {
            font-size: 12px;
          }
        }
      }
  
      .items {
        display: flex;
        align-items: center;

        @media (max-width: 1700px){
          padding-left: 20px;
          padding-right: 20px;
        }

        .item {
          display: flex;
          align-items: center;
          margin-left: 50px;
          position: relative;
          cursor: pointer;
          font-size: 18px;
          
          a{
            color: white;
            font-family: "Mukta",sans-serif;
            text-transform: uppercase;
            border-bottom: 3px solid transparent;
            transition: all 0.3s ease;

            &:hover{
              color:#D4AF37; 
              border-bottom: 3px solid #D4AF37;
            }
          }
  
          .icon {
            font-size: 20px;
          }
  
          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
  
          .counter {
            width: 15px;
            height: 15px;
            background-color: red;
            border-radius: 50%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-weight: bold;
            position: absolute;
            top: -5px;
            right: -5px;
          }
        }
      }
    }
  }