.home-faq {
    padding: 40px 0px 140px;
    font-family: 'Mukta', sans-serif;

    .show-more-faq-btn{
        margin-top: 24px;
        text-align: center;
    }

    .additional-content{
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: opacity 0.5s ease, height 0.5s ease;
    }

    .additional-content.show{
        opacity: 1;
        height: auto;
    }

    h3{
        font-size: 24px;
        margin-bottom: 8px;
        font-family: 'Mukta', sans-serif;
        font-weight: 500;
        line-height: 32px;
    }

    

    .MuiPaper-root.MuiAccordion-root{
        border: 0;
        border-bottom: 1px solid #e2e8f0;
        box-shadow: none;
        border-radius: 0;
        margin: 0;
        background: transparent;
        box-sizing: border-box;
        font-family: 'Mukta', sans-serif;
    }

    .MuiAccordionSummary-root{
        padding-left: 0px;
        padding-right: 0px;
    }
}


